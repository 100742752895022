.stats {
  padding: 8px;
  height: 460px;
  background-color: white;
  width: 100%;

  &:hover {
    border: 2px solid #92e2de;
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.custom-tooltip {
  background: #fafafa;
}

.label {
  background: linear-gradient(-150deg, #1ab394, #1a91ae);
  text-align: center;
  color: black;
}
