//@import url('https://fonts.googleapis.com/css2?family=Anton&family=Original+Surfer&family=Yanone+Kaffeesatz:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Copse&family=Red+Hat+Display:wght@500&family=Vesper+Libre&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Lobster+Two&family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');


// Variables
:root{
    /*--original-surfer : 'Original Surfer', cursive;
    --anton : 'Anton', sans-serif;
    --yanone : 'Yanone Kaffeesatz', sans-serif;
    --redhat : 'Red Hat Display', sans-serif;
    --vesper : 'Vesper Libre', serif;
    --copse: 'Copse', serif;
    --gilroy : 'Gilroy', sans-serif;
    --lobster : 'Lobster Two';
    --lucky : 'Luckiest Guy';*/
    --poppins : 'Poppins', sans-serif;
    --mavenpro : 'Maven Pro', sans-serif;
    --backgroundImg : linear-gradient(-150deg, #1ab394, #1a91ae);
}
